import { template as template_85a66a5c70124e4c9f518e7696d9aeeb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_85a66a5c70124e4c9f518e7696d9aeeb(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
