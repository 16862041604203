import { template as template_f280b6d1028049f0bd222e85dd453d0c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_f280b6d1028049f0bd222e85dd453d0c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
