import { template as template_48517a686f054567bc4c349b40925ced } from "@ember/template-compiler";
const FKLabel = template_48517a686f054567bc4c349b40925ced(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
