import { template as template_53414176c37d40e39595ba27ce593e31 } from "@ember/template-compiler";
const SidebarSectionMessage = template_53414176c37d40e39595ba27ce593e31(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
